import { getPrintablesService } from '../hooks/PrintablesHooks';

const getLogo = async (logoId, locale) => {
  const printablesService = getPrintablesService();
  return printablesService.getLogo(logoId, locale);
};

export const getEmbeddedPage = async (collectionId, locale) => {
  const printablesService = getPrintablesService();
  return printablesService.getEmbeddedPage(collectionId, locale);
};

export const getLogos = async (logoIds, locale) => {
  const allLogos = await Promise.allSettled(logoIds?.map((id) => getLogo(id, locale)));
  return allLogos
    .filter(({ status }) => status === 'fulfilled')
    .map(({ value }) => value?.logo?.url);
};

const getPrintable = async (printableId, locale) => {
  const printablesService = getPrintablesService();
  return printablesService.getPrintable(printableId, locale);
};
export const getAllPrintables = async (printableIds, locale) => {
  if (printableIds) {
    const allPrintablesPromises = printableIds.map((id) => getPrintable(id, locale));
    const allPrintablesSettled = await Promise.allSettled(allPrintablesPromises);
    return allPrintablesSettled
      .filter(({ status }) => status === 'fulfilled')
      .filter(({ value }) => value !== null && value !== undefined)
      .map(({ value: printable }) => ({
        id: printable?.uid,
        src: printable?.printable_thumbnail_image?.url,
        title: printable?.printable_title,
        description: printable?.printable_image_description,
        slugify: printable?.printable_file?.url,
      }));
  }
  return undefined;
};

export const getFooter = async (entryId, locale) => {
  const printablesService = getPrintablesService();
  const footer = await printablesService.getFooter(entryId, locale);
  return { ...footer, multi_line: footer?.multi_line?.split('\n') };
};
