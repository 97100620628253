import { css } from 'styled-components';

const theme = {
  viewport: {
    maxSize: '1280px',
  },
  colors: {
    main: '#027AAE',
    hpBlack: '#212121',
    hpWhite: '#ffffff',
    aqua05: '#41d6bd',
    lightGray: '#5f5e5e',
    foreground5: 'rgba(33, 33, 33, 0.05)',
    pageBackground: '#fafbfc',
    grayScale03: '#f1f1f1',
    lightModePrimary5: 'rgba(2, 122, 174, 0.05)',
  },
  font: {
    family: {
      hpLight: 'HPSimplifiedLight',
      hpRegular: 'HPSimplifiedRegular',
      gothamBold: 'Gotham-Bold',
    },
    size: {
      h2: '36px',
      sectionTitle: '26px',
      paragraph: '16px',
      mediumParagraph: '14px',
      smallParagraph: '12px',
      partner: '12px',
      seeAll: '12px',
      seeAllDesktop: '24px',
      partnerTitle: {
        mobile: '18px',
        tablet: '32px',
        desktop: '40px',
      },
      mobile: {
        homeSection: '18px',
        sectionTitle: '26px',
      },
      tablet: {
        sectionTitle: '32px',
      },
      desktop: {
        sectionTitle: '40px',
      },
    },
    color: {
      default: '#1c1c1c',
      hpPrimary: '#027aae',
      hpBlack: '#212121',
      disabled: '#b2b2b2',
      darkGrey: '#979797',
      lightModeHover: '#006691',
    },
  },
  spacing: {
    mobile: {
      page: '16px',
      grid: {
        gap: '8px',
        default: '16px',
      },
      collectionGrid: {
        gap: '8px',
      },
      section: {
        marginTop: '30px',
      },
    },
    desktop: {
      page: '48px',
      grid: {
        gap: '8px',
        default: '162px',
      },
      collectionGrid: {
        gap: '23px',
      },
      section: {
        marginTop: '50px',
        titleMargin: '16px',
      },
    },
    x1: '16px',
    x2: '24px',
    x3: '32px',
    x4: '48px',
  },
  home: {
    card: {
      mobile: {
        width: '90px',
        height: '120px',
        size: css`
          width: 90px;
          height: 120px;
        `,
      },
      desktop: {
        width: '205px',
        height: '262px',
        size: css`
          width: 135px;
          height: 178px;
        `,
      },
    },
  },
  contentPage: {
    font: {
      desktop: {
        title: '40px',
      },
      mobile: {
        title: '32px',
      },
    },
  },

  defaultRadius: '4px',
};

export default theme;
