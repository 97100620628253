import styled from 'styled-components';
import device from '../../devices';

export const CarouselTitle = styled.p`
  color: ${({ theme }) => theme.font.color.darkGrey};
  font-size: ${({ theme }) => theme.font.size.h2};
  position: absolute;
  font-weight: bold;
  bottom: 25px;
  bottom: 7%;
  left: 5%;
`;

export const CarouselBackground = styled.img`
  width: 100%;
`;

export const CarouselContainer = styled.section`
  position: relative;
  margin: 0 auto;
`;

export const NavigationGroup = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const NavigationContainer = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  //max-width: 1280px;
  margin: 0 auto;
  height: 100%;
`;

const NavigationButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  top: -32px;
  bottom: 0;
  margin: auto;
  background-color: white;
  border: none;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    visibility: visible;
  }

  &:active {
    border: 1px solid rgba(0, 0, 0, 0.45);
  }
`;

export const LeftNavigation = styled(NavigationButton)`
  left: 16px;
`;

export const RightNavigation = styled(NavigationButton)`
  right: 16px;
`;

function activeTemplate(i, color) {
  return `
      &:nth-child(${i + 1}){
          border: 2px solid ${color};
       }
    `;
}

function bulletColor(colors) {
  let str = '';
  const size = colors.length;
  for (let index = 0; index < size; index += 1) {
    str += activeTemplate(index, colors[index]);
  }
  return str;
}

export const PaginationContainer = styled.div`
  width: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0;
  position: relative;

  .swiper-pagination-bullet {
    margin: 0;
    width: 8px;
    height: 8px;
    border: 2px solid ${({ theme }) => theme.colors.pageBackground};
    background: ${({ theme }) => theme.colors.lightGray};

    &-active {
      border: 2px solid ${({ theme }) => theme.colors.aqua05};
      background: ${({ theme }) => theme.colors.hpWhite};
      ${({ colors }) => bulletColor(colors)};
    }
  }
`;
