import { keyframes } from 'styled-components';

const loadingKeyframe = keyframes`
  from {
    transform: translate(-100%,-100%)
  }
  to {
    transform: translate(100%,100%)
  }
`;

export default loadingKeyframe;
