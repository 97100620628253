import styled from 'styled-components';

export const PrintablesContainer = styled.div`
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
`;

export const PageContainer = styled.div`
  ${(props) => props.background && `background-image: url('${props.background}');`};
  ${(props) => props.background && `background-size: cover;`};
  ${(props) => props.background && `background-repeat: no-repeat;`};
  ${(props) => props.background && `background-position-x: center;`};
  ${(props) => props.background && `background-position-y: center;`};
  ${(props) => !props.backgroundColor && `background: #fafbfc;`};
  ${(props) => props.backgroundColor && `background: ${props.backgroundColor};`};
  position: relative;
  padding: 20px 0;
`;

export const SkeletonTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const SkeletonSubTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;
