import React from 'react';
import * as PropTypes from 'prop-types';
import {
  DisclaimerContainer,
  DisclaimerText,
  Link,
  Logo,
  LogoContainer,
  LogoText,
  MainContainer,
  SmallDisclaimerContainer,
  SmallDisclaimerText,
} from './styles';

export const Footer = ({ header, subHeader, logo }) => (
  <MainContainer>
    <LogoContainer>
      {logo.href && (
        <>
          <Link href={logo.href} target="_blank" rel="noreferrer">
            <Logo src={logo.url} alt={logo.alt} />
          </Link>
          <Link href={logo.href} target="_blank" rel="noopener noreferrer">
            <LogoText>{logo.title}</LogoText>
          </Link>
        </>
      )}
    </LogoContainer>
    <DisclaimerContainer>
      {header?.href && (
        <a href={header?.href} target="_blank" rel="noreferrer">
          <DisclaimerText>{header?.title}</DisclaimerText>
        </a>
      )}
      <SmallDisclaimerContainer>
        {subHeader.map((item) => (
          <SmallDisclaimerText key={item}>{item}</SmallDisclaimerText>
        ))}
      </SmallDisclaimerContainer>
    </DisclaimerContainer>
  </MainContainer>
);
Footer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  subHeader: PropTypes.array,
  header: PropTypes.shape({
    href: PropTypes.string,
    title: PropTypes.string,
  }),
  logo: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
  }),
};
Footer.defaultProps = {
  header: {
    href: '',
    title: '',
  },
  subHeader: [],
  logo: {
    url: '',
    alt: '',
    href: '',
    title: '',
  },
};

export default { Footer };
