import styled from 'styled-components';

export const Text = styled.p`
  font-family: HP Simplified;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
export const Anchor = styled.a`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border-color: #027aae;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-align: center;
  box-pack: center;
  box-shadow: none;
  box-sizing: border-box;
  color: #027aae;
  cursor: pointer;
  display: inline-flex;
  font-family: 'HP Simplified', Arial, sans-serif;
  font-size: 16px;
  justify-content: center;
  line-height: 20px;
  margin: 0px;
  outline: 0px;
  padding: calc(8px - 1px) calc(28px - 1px);
  position: relative;
  tap-highlight-color: transparent;
  text-align: center;
  transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s, color 0.3s ease 0s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &&:hover {
    background-color: rgba(2, 122, 174, 0.05);
    border-color: #035c84;
    color: #035c84;
    text-decoration: none;
  }
`;
export default { Text };
