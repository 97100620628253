import styled from 'styled-components';

export const Subtitle = styled.p`
  font-family: HP Simplified;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: center;
  margin-bottom: 47px;
  @media (max-width: 767px) {
    font-family: HP Simplified;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-align: center;
  }
`;

export const Logo = styled.img`
  height: 40px;
`;

export const LogoContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;
export const PlusIcon = styled.img`
  margin: 0 15px;
`;
export const SkeletonHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

export const Text = styled.p`
  font-family: HP Simplified;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: center;
  color: #006691;
  @media (max-width: 767px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 32px;
  }
`;
