import React from 'react';
import { PlusIcon, SkeletonHeaderContainer } from './Header.styles';
import plusIcon from '../../assets/images/plus.svg';

import { LogoSkeleton } from './LogoSkeleton';
import { TextSkeleton } from './TextSkeleton';

export const HeaderSkeleton = () => (
  <>
    <SkeletonHeaderContainer>
      <LogoSkeleton />
      <PlusIcon src={plusIcon} />
      <LogoSkeleton />
    </SkeletonHeaderContainer>
    <TextSkeleton />
    <TextSkeleton />
  </>
);

export default HeaderSkeleton;
