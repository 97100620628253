import { PrintablesService } from '../sdk/PrintablesService';
import { ContentStackService } from '../sdk/ContentStackService';
import { env } from '../sdk/constants/environment';

let printablesServiceInstance = null;
export const getPrintablesService = () => {
  try {
    if (printablesServiceInstance === null) {
      printablesServiceInstance = new PrintablesService(new ContentStackService(env.contentStack));
    }
  } catch (error) {
    console.error(error);
  }
  return printablesServiceInstance;
};

export default { getPrintablesService };
