import * as Contentstack from 'contentstack';
import { ContentType } from './constants/ContentType';

export class ContentStackService {
  constructor({ apiKey, deliveryToken, environmentName }) {
    this.stack = Contentstack.Stack(apiKey, deliveryToken, environmentName);
    this.stack.setCachePolicy(Contentstack.CachePolicy.CACHE_THEN_NETWORK);
  }

  async getEntry(contentType, collectionId, locale) {
    return this.stack
      .ContentType(contentType)
      .Entry(collectionId)
      .addParam('locale', locale)
      .toJSON()
      .fetch();
  }

  async getLogo(logoEntry, locale) {
    return this.getEntry(ContentType.logo, logoEntry, locale);
  }

  async getEmbeddedPage(embeddedPageId, locale) {
    return this.getEntry(ContentType.embedcollection, embeddedPageId, locale);
  }

  async getPrintable(printableId, locale) {
    return this.getEntry(ContentType.printable, printableId, locale);
  }

  async getFooter(entryId, locale) {
    return this.getEntry(ContentType.embedCollectionFooter, entryId, locale);
  }
}

export default ContentStackService;
