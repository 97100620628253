import ContentLoader from 'react-content-loader';
import React from 'react';

export const ContentSkeleton = () => (
  <ContentLoader
    speed={2}
    width={143}
    height={186}
    viewBox="0 0 143 186"
    backgroundColor="#ffffff"
    foregroundColor="#cccccc"
  >
    <rect x="0" y="0" rx="0" ry="0" width="143" height="186" />
  </ContentLoader>
);

export default ContentSkeleton;
