import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Cover,
  Description,
  Link,
  PrintableInfoContainer,
  PrintableTitle,
} from './styles';

const ListThumbnail = ({
  src,
  title,
  description,
  useCardSize,
  hasMarginBottom,
  className,
  slugify,
  printableId,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  useLayoutEffect(() => {
    if (imgRef.current) {
      imgRef.current.onload = () => setIsLoaded(true);
    }
  }, [src, title]);

  return (
    <Link
      to={{
        pathname: slugify,
        state: { printableId },
      }}
      target="_blank"
      $hasMarginBottom={hasMarginBottom}
      title={`${title} - ${description}`}
    >
      <Container isLoaded={isLoaded} useCardSize={useCardSize} className={className}>
        <Cover
          loading="lazy"
          src={src}
          alt={`${title} - ${description}`}
          ref={imgRef}
          useCardSize={useCardSize}
        />
        <Description>{description}</Description>
        <PrintableInfoContainer>
          <PrintableTitle>{title}</PrintableTitle>
        </PrintableInfoContainer>
      </Container>
    </Link>
  );
};

ListThumbnail.defaultProps = {
  useCardSize: false,
  hasMarginBottom: false,
  className: '',
};

ListThumbnail.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  slugify: PropTypes.string.isRequired,
  printableId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  useCardSize: PropTypes.bool,
  hasMarginBottom: PropTypes.bool,
};

export default ListThumbnail;
