import ContentLoader from 'react-content-loader';
import React from 'react';
import { SkeletonSubTitleContainer } from '../../pages/PrintablesPage.styles';
import skeletonConfig from '../../sdk/util/skeletonConfig';

export const TextSkeleton = () => (
  <SkeletonSubTitleContainer>
    <ContentLoader {...skeletonConfig} width={500} height={64} viewBox="0 0 500 64">
      <rect x="10" y="12" rx="0" ry="0" width="487" height="25" />
    </ContentLoader>
  </SkeletonSubTitleContainer>
);

export default TextSkeleton;
