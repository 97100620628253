import React from 'react';
import './i18n';
import { ThemeProvider as VeneerThemeProvider } from '@veneer/core';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import theme from './theme';
import { PrintablesPage } from './pages/PrintablesPage';

function App() {
  return (
    <VeneerThemeProvider density="high">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/:collectionId">
              <PrintablesPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </VeneerThemeProvider>
  );
}

export default App;
