import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px 48px;
  justify-content: space-around;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  @media (max-width: 767px) {
    margin-bottom: 16px;
    justify-content: center;
  }
`;

export const Link = styled.a`
  :hover {
    text-decoration: none;
  }
`;
export const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 767px) {
    align-items: center;
  }
`;
export const Text = styled.span`
  font-family: HP Simplified;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  color: #212121; ;
`;

export const DisclaimerText = styled.div`
  font-family: HP Simplified;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #212121;

  @media (max-width: 425px) {
    margin-bottom: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #212121;
  }
`;

export const SmallDisclaimerText = styled.span`
  font-family: HP Simplified;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  color: #212121;
`;

export const LogoText = styled.span`
  font-family: HP Simplified;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #212121;
`;

export const SmallDisclaimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Logo = styled.img`
  height: 40px;
`;

export default {
  MainContainer,
  LogoContainer,
  Text,
  SmallDisclaimerContainer,
  SmallDisclaimerText,
};
