import React from 'react';
import * as PropTypes from 'prop-types';
import { Container, Text, Anchor } from './styles';

export const MorePrintables = ({ callToAction, buttonText, link }) => (
  <>
    <Container>
      <Text>{callToAction}</Text>
    </Container>
    {buttonText && link && (
      <Container>
        <Anchor href={link} target="_blank" rel="noreferrer" style={{ width: 176 }}>
          {buttonText}
        </Anchor>
      </Container>
    )}
  </>
);

MorePrintables.propTypes = {
  callToAction: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
};

MorePrintables.defaultProps = {
  callToAction: 'To find thousands of printables, visit:',
  buttonText: '',
  link: '',
};

export default { MorePrintables };
