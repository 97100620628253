import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'uniqid';
import { Logo, LogoContainer, PlusIcon, Subtitle, Text } from './Header.styles';
import plusIcon from '../../assets/images/plus.svg';

export const Header = ({ logos, subtitle, callToAction, hidden }) => (
  <>
    <LogoContainer hidden={hidden}>
      {logos.map((logo, index) => {
        switch (index) {
          case 0:
            return (
              <>
                <Logo hidden={hidden} src={logo} alt="Logo" />
              </>
            );
          default:
            return (
              <>
                <PlusIcon key={uniqueId()} hidden={hidden} src={plusIcon} alt="Plus Icon" />
                <Logo key={uniqueId()} hidden={hidden} src={logo} alt="Logo" />
              </>
            );
        }
      })}
    </LogoContainer>
    <Subtitle hidden={hidden}>{subtitle}</Subtitle>
    <Text>{callToAction}</Text>
  </>
);

Header.defaultProps = {
  hidden: false,
};

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  logos: PropTypes.array.isRequired,
  subtitle: PropTypes.string.isRequired,
  callToAction: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};
export default Header;
