const deviceSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const createMinQuery = (querySize) => `(min-width: ${querySize}px)`;
const device = {
  mobileS: createMinQuery(deviceSize.mobileS),
  mobileM: createMinQuery(deviceSize.mobileM),
  mobileL: createMinQuery(deviceSize.mobileL),
  tablet: createMinQuery(deviceSize.tablet),
  laptop: createMinQuery(deviceSize.laptop),
  laptopL: createMinQuery(deviceSize.laptopL),
  desktop: createMinQuery(deviceSize.desktop),
  desktopL: createMinQuery(deviceSize.desktop),
};

export { deviceSize };
export default device;
