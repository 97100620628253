import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import usEn from './en-us.json';
import caFr from './fr-ca.json';
import ukEn from './en-uk.json';
import brPt from './pt-br.json';
import nlNl from './nl-nl.json';
import chDe from './de-ch.json';
import dkDa from './da-dk.json';
import grEl from './el-gr.json';
import esEs from './es-es.json';
import fiFi from './fi-fi.json';
import beFr from './fr-be.json';
import chFr from './fr-ch.json';
import frFr from './fr-fr.json';
import jpJa from './ja-jp.json';
import krKo from './ko-kr.json';
import beNl from './nl-be.json';
import noNo from './no-no.json';
import plPl from './pl-pl.json';
import ptPt from './pt-pt.json';
import ruRu from './ru-ru.json';
import seSv from './sv-se.json';
import thTh from './th-th.json';
import trTr from './tr-tr.json';
import vnVi from './vi-vn.json';
import cnZh from './zh-cn.json';

i18n.use(initReactI18next).init({
  resources: {
    caFr,
    usEn,
    ukEn,
    brPt,
    nlNl,
    chDe,
    dkDa,
    grEl,
    esEs,
    fiFi,
    beFr,
    chFr,
    frFr,
    jpJa,
    krKo,
    beNl,
    noNo,
    plPl,
    ptPt,
    ruRu,
    seSv,
    thTh,
    trTr,
    vnVi,
    cnZh,
    atDe: chDe,
    gbEn: ukEn,
    caEn: ukEn,
    auEn: ukEn,
    nzEn: ukEn,
    phEn: ukEn,
    myEn: ukEn,
    sgEn: usEn,
  },
  fallbackLng: ['usEn'],
  lng: 'usEn',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
