import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconChevronLeft, IconChevronRight } from '@veneer/core';

import { useTranslation } from 'react-i18next';
import {
  CarouselContainer,
  LeftNavigation,
  NavigationContainer,
  NavigationGroup,
  RightNavigation,
} from './styles';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const Carousel = ({ children, timeBetweenSlidesMS, id, rowCount }) => {
  const { t } = useTranslation();
  return children?.length > 0 ? (
    <CarouselContainer id={id}>
      <Swiper
        pagination={{ clickable: true, el: '#pagination-container' }}
        role="complementary"
        centerInsufficientSlides
        slidesPerColumnFill="row"
        slidesPerColumn={rowCount}
        spaceBetween={10}
        keyboard={{ enabled: true, pageUpDown: true }}
        breakpoints={{
          320: {
            slidesPerGroup: 2.6,
            slidesPerView: 2.6,
          },
          360: {
            slidesPerGroup: 3.5,
            slidesPerView: 3.5,
          },
          540: {
            slidesPerGroup: 4.5,
            slidesPerView: 4.5,
          },
          768: {
            slidesPerGroup: 6,
            slidesPerView: 6,
          },
          1000: {
            slidesPerGroup: 6,
            slidesPerView: 6,
          },
        }}
        autoplay={{
          delay: timeBetweenSlidesMS,
          disableOnInteraction: true,
        }}
        navigation={{
          nextEl: '#rightArrow-carousel',
          prevEl: '#leftArrow-carousel',
        }}
        a11y={{
          prevSlideMessage: t('Previous slide'),
          nextSlideMessage: t('Next slide'),
        }}
      >
        <NavigationGroup>
          <NavigationContainer>
            <LeftNavigation id="leftArrow-carousel">
              <IconChevronLeft />
            </LeftNavigation>
            <RightNavigation id="rightArrow-carousel">
              <IconChevronRight />
            </RightNavigation>
          </NavigationContainer>
        </NavigationGroup>

        {children.map((slide, i) => (
          <SwiperSlide key={`children-index-${i + 1}`}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </CarouselContainer>
  ) : (
    <></>
  );
};

Carousel.defaultProps = {
  timeBetweenSlidesMS: 5000,
  id: '',
  rowCount: 2,
};

Carousel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  timeBetweenSlidesMS: PropTypes.number,
  rowCount: PropTypes.number,
};

export default Carousel;
