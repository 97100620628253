import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SecureLink = styled(Link).attrs({
  rel: 'noopener noreferrer',
})`
  display: block;
  background: none;
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
`;

export default SecureLink;
