import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import * as qs from 'qs';
import { PageContainer, PrintablesContainer } from './PrintablesPage.styles';
import { Header } from '../components/Header/Header';
import {
  PrintablesPageReducer,
  PrintablesPageReducerInitialState,
  updateFooter,
  updateLogos,
  updatePageData,
  updatePrintables,
} from './PrintablePages.reducer';
import { getAllPrintables, getEmbeddedPage, getFooter, getLogos } from './PrintablePages.actions';
import { HeaderSkeleton } from '../components/Header/HeaderSkeleton';
import Carousel from '../components/Carousel';
import ListThumbnail from '../components/ListThumbnail';
import { ContentSkeleton } from '../components/CarouselSkeleton/CarouselSkeleton';
import { MorePrintables } from '../components/MorePrintables';
import { Footer } from '../components/Footer';
import { LoadingState } from '../sdk/constants/LoadingState';

export const PrintablesPage = () => {
  const { collectionId } = useParams();
  const { location } = useHistory();
  const { search } = location;
  let rowCount = 2;
  let locale = 'en-us';
  if (search && search.match('row_count')) {
    // eslint-disable-next-line camelcase
    const parsedQuery = qs.parse(search.toString().replace('?', ''));
    // eslint-disable-next-line camelcase
    rowCount = parseInt(parsedQuery.row_count, 10);
    rowCount = Math.min(Math.abs(rowCount), 2);
    if (parsedQuery.locale) {
      locale = parsedQuery.locale;
    }
  }

  const [state, dispatch] = useReducer(PrintablesPageReducer, PrintablesPageReducerInitialState);
  const isEmbeddedPageFirstLoad = state.embeddedPageState === LoadingState.FirstLoad;
  const isEmbeddedPageLoaded = state.embeddedPageState === LoadingState.Loaded;
  const isHeaderStateFirstLoad = state.headerState === LoadingState.FirstLoad;
  const isHeaderStateLoaded = state.headerState === LoadingState.Loaded;
  const isPrintablesCarouselLoaded = state.carouselState === LoadingState.Loaded;
  const isPrintablesCarouselFirstLoad = state.carouselState === LoadingState.FirstLoad;
  const isFooterStateFirstLoad = state.footerState === LoadingState.FirstLoad;
  const isFooterStateLoaded = state.footerState === LoadingState.Loaded;
  const parent = document.referrer;
  useEffect(() => {
    if (isEmbeddedPageFirstLoad) {
      getEmbeddedPage(collectionId, locale)
        .then((result) => dispatch(updatePageData(result)))
        .catch((error) => console.error(error));
    }
  }, [isEmbeddedPageFirstLoad, collectionId, locale]);

  useEffect(() => {
    if (isEmbeddedPageLoaded && isHeaderStateFirstLoad) {
      getLogos(state?.page?.logo_header, locale)
        .then((logos) => dispatch(updateLogos(logos)))
        .catch((error) => console.error(error));
    }
  });

  useEffect(() => {
    if (isEmbeddedPageLoaded && isPrintablesCarouselFirstLoad) {
      getAllPrintables(state?.page?.collection_creator, locale).then((printables) =>
        dispatch(updatePrintables(printables)),
      );
    }
  });
  useEffect(() => {
    if (isEmbeddedPageLoaded && isFooterStateFirstLoad) {
      getFooter(state?.page?.footer_reference[0]?.uid, locale).then((result) => {
        dispatch(updateFooter(result));
      });
    }
  });
  return (
    <>
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content="frame-ancestor https://*.hp.com*" />
      </Helmet>
      <PageContainer
        background={state?.page?.banner_background_texture?.url}
        backgroundColor={state?.page?.background_color}
      >
        {isHeaderStateLoaded ? (
          <Header
            logos={state.logos}
            subtitle={state.subtitle}
            callToAction={state.callToAction}
            hidden={parent.includes('hp.com')}
          />
        ) : (
          <HeaderSkeleton />
        )}

        <PrintablesContainer>
          {!isPrintablesCarouselLoaded && isEmbeddedPageLoaded && (
            <Carousel rowCount={rowCount}>
              {state.page?.collection_creator?.map((item) => (
                <ContentSkeleton key={item} />
              ))}
            </Carousel>
          )}

          {isPrintablesCarouselLoaded && (
            <Carousel rowCount={rowCount}>
              {state.printables?.map((printable, index) => (
                <ListThumbnail
                  key={printable.id}
                  src={printable.src}
                  title={printable.title}
                  id={`printable-${index}`}
                  description={printable.description}
                  printableId={printable.id}
                  slugify={printable.slugify}
                  useCardSize
                  hasMarginBottom
                />
              ))}
            </Carousel>
          )}
        </PrintablesContainer>
        {state?.page?.call_to_action_website && (
          <MorePrintables
            callToAction={state?.page?.call_to_action_website}
            link={state?.page?.printables_website_url}
            buttonText={state?.page?.printables_website_text}
          />
        )}
      </PageContainer>

      {isFooterStateLoaded && !parent.includes('hp.com') && (
        <Footer
          header={state?.footer?.footer_header_link}
          subHeader={state?.footer?.multi_line}
          logo={{
            url: state?.footer?.footer_logo?.url,
            alt: state?.footer?.footer_logo_description,
            title: state?.footer?.footer_logo_link?.title,
            href: state?.footer?.footer_logo_link?.href,
          }}
        />
      )}
    </>
  );
};

export default PrintablesPage;
