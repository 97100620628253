import { LoadingState } from '../sdk/constants/LoadingState';

export const PrintablesPageReducerInitialState = {
  embeddedPageState: LoadingState.FirstLoad,
  headerState: LoadingState.FirstLoad,
  carouselState: LoadingState.FirstLoad,
  footerState: LoadingState.FirstLoad,
  page: null,
  callToAction: null,
  subtitle: null,
  logos: null,
  printables: null,
  footer: null,
};

export const UPDATE_EMBEDDED_PAGE_DATA = 'UPDATE_EMBEDDED_PAGE_DATA';
export const UPDATE_LOGO_DATA = 'UPDATE_LOGO_DATA';
export const UPDATE_PRINTABLES_DATA = 'UPDATE_PRINTABLES_DATA';
export const UPDATE_FOOTER_DATA = 'UPDATE_FOOTER_DATA';
export const updateFooter = (payload) => ({ type: UPDATE_FOOTER_DATA, payload });
export const updatePageData = (payload) => ({
  type: UPDATE_EMBEDDED_PAGE_DATA,
  payload,
});
export const updateLogos = (payload) => ({
  type: UPDATE_LOGO_DATA,
  payload,
});
export const updatePrintables = (payload) => ({
  type: UPDATE_PRINTABLES_DATA,
  payload,
});
export const PrintablesPageReducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_EMBEDDED_PAGE_DATA:
      return {
        ...state,
        page: payload,
        subtitle: payload?.logo_subtitle,
        callToAction: payload?.call_to_action,
        embeddedPageState: LoadingState.Loaded,
      };
    case UPDATE_LOGO_DATA:
      return { ...state, logos: payload, headerState: LoadingState.Loaded };
    case UPDATE_PRINTABLES_DATA:
      return {
        ...state,
        printables: payload,
        carouselState: LoadingState.Loaded,
      };
    case UPDATE_FOOTER_DATA:
      return {
        ...state,
        footer: { ...payload },
        footerState: LoadingState.Loaded,
      };
    default:
      return state;
  }
};
