export class PrintablesService {
  constructor(provider) {
    if (provider === null || provider === undefined) {
      throw new Error('[PrintablesService] A provider should be used for this service');
    }
    this.provider = provider;
  }

  async getEmbeddedPage(embeddedPageId, locale) {
    let result = null;
    try {
      result = await this.provider.getEmbeddedPage(embeddedPageId, locale);
    } catch (error) {
      return result;
    }
    return result;
  }

  async getLogo(entryId, locale) {
    let result = null;
    try {
      result = await this.provider.getLogo(entryId, locale);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPrintable(entryId, locale) {
    let result = null;
    try {
      result = await this.provider.getPrintable(entryId, locale);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getFooter(entryId, locale) {
    let result = null;
    try {
      result = await this.provider.getFooter(entryId, locale);
    } catch (error) {
      console.error(error);
    }
    return result;
  }
}

export default PrintablesService;
