import styled, { css } from 'styled-components';
import loadingKeyframe from '../../UI/keyframes/loading';
import device from '../../devices';
import SecureLink from '../SecureLink';

export const Link = styled(SecureLink).attrs({ download: true })`
  margin-bottom: ${({ theme, hasMarginBottom }) =>
    hasMarginBottom ? theme.spacing.mobile.collectionGrid.gap : 0};

  :focus {
    filter: brightness(1.05);
    box-shadow: 0 0 6pt 0pt #a9ccdf;
  }

  display: flex;
  justify-content: center;
`;

export const imgCardSize = css`
  ${({ theme }) => theme.home.card.mobile.size} @media ${device.laptop} {
    ${({ theme }) => theme.home.card.desktop.size}
  }
`;

const defaultImgSize = css`
  min-height: 120px;

  @media ${device.tablet} {
    min-height: 140px;
  }

  @media ${device.laptop} {
    min-height: 190px;
  }
`;

const imgSize = css`
  ${({ useCardSize }) => (useCardSize ? imgCardSize : defaultImgSize)}
`;

export const Cover = styled.img`
  vertical-align: top;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  border: 4px solid white;
`;

export const PrintableInfoContainer = styled.div`
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.8)
  );
  z-index: 2;
  display: flex;
  align-items: flex-end;
  transition: all 250ms ease-in-out;
`;

export const Container = styled.figure`
  ${imgSize}
  background: ${({ theme }) => theme.colors.foreground5};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-sizing: border;
  border-radius: 4px;
  box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.15);
  margin: 0 10px;

  :hover {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
    @media ${device.laptop} {
      > ${PrintableInfoContainer} {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  ::before {
    content: '';
    display: ${({ isLoaded }) => (isLoaded ? 'none' : 'block')};
    position: absolute;
    left: -100%;
    top: 0;
    bottom: 0;

    height: 200%;
    width: 200%;
    background: linear-gradient(to bottom right, transparent 40%, #ffffffaa 50%, transparent 60%);
    animation: ${loadingKeyframe} 1.3s cubic-bezier(0.04, 0.32, 0.99, 0.55) infinite;
  }
`;

export const Description = styled.figcaption`
  display: none;
`;

export const PrintableTitle = styled.p`
  font-size: ${({ theme }) => theme.font.size.mediumParagraph};
  font-family: ${({ theme }) => theme.font.family.hpRegular};
  color: ${({ theme }) => theme.colors.hpWhite};
  line-height: 1.29;
  padding: 8px;
`;
