import React from 'react';
import ContentLoader from 'react-content-loader';
import skeletonConfig from '../../sdk/util/skeletonConfig';

export const LogoSkeleton = () => (
  <ContentLoader {...skeletonConfig} width={40} height={40} viewBox="0 0 40 40">
    {/* eslint-disable-next-line react/react-in-jsx-scope */}
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
);

export default LogoSkeleton;
